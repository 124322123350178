import React from 'react';
import styled from 'styled-components';

interface CardSliderProps {
  children: React.ReactNode;
}

export const CardSlider: React.FC<CardSliderProps> = ({ children }) => (
  <SliderWrapper>{children}</SliderWrapper>
);

const SliderWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 1.5rem;
  overflow-x: auto;
  padding: 1rem 1rem 1rem 2rem;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }
`;
