import React from 'react';
import styled, { keyframes } from 'styled-components';
import { IoTrashOutline } from 'react-icons/io5';
import { PopConfirm } from '@/components/pop-confirm';
import { Card } from '@/components/card';
import { Chip } from '@/components/chip';
import { LabelsList } from '@/components/labels-list';

export interface OptionsCardProps {
  title: string;
  options: string[];
  onAddKey: (key: string) => Promise<void>;
  onRemoveKey: (key: string) => Promise<void>;
  onDelete: () => Promise<void>;
  isDraft?: boolean;
  onEditTitle?: (value: string) => void;
  removeText?: string;
  optionsCollapseAfter?: number;
}

export const OptionsCard: React.FC<OptionsCardProps> = ({
  title,
  options,
  onAddKey,
  onRemoveKey,
  onDelete,
  isDraft = false,
  onEditTitle,
  removeText,
  optionsCollapseAfter,
}) => (
  <CardWrapper>
    <ControlsWrapper>
      <div className="options-card-delete">
        <PopConfirm
          onConfirm={onDelete}
          text={
            removeText ||
            `Are you sure you want to remove this card? This action cannot be undone.`
          }
        >
          <IoTrashOutline />
        </PopConfirm>
      </div>
    </ControlsWrapper>
    <ContentSection>
      {isDraft && onEditTitle ? (
        <Chip mode="editable" onEdit={onEditTitle} clearAfterEdit>
          {title || `+ ADD TITLE`}
        </Chip>
      ) : (
        <Header>{title}</Header>
      )}
      {!isDraft || (isDraft && title.trim()) ? (
        <LabelsList
          labels={options.map((key) => ({ label: key, isSelected: true }))}
          collapseAfter={optionsCollapseAfter || 20}
          alwaysSelected
          allowAppend
          appendText={isDraft ? `+ ADD NEW KEY` : `+ ADD NEW OPTION`}
          onAddLabel={onAddKey}
          onRemoveLabel={onRemoveKey}
          maxWidth="300px"
          maxHeight={isDraft ? `250px` : `350px`}
        />
      ) : (
        <PlaceholderMessage>
          Please enter a namespace before adding keys.
        </PlaceholderMessage>
      )}
    </ContentSection>
  </CardWrapper>
);

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
`;

const ControlsWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .options-card-delete {
    cursor: pointer;
    opacity: 0.4;
    animation: ${FadeIn} 0.2s ease-in-out;
  }
`;

const CardWrapper = styled(Card)`
  position: relative;
  flex: 0 0 auto;
  min-width: 250px;
  min-height: 200px;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  scroll-snap-align: start;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  &:hover ${ControlsWrapper} {
    opacity: 1;
  }
`;

const ContentSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.75rem;
  font-family: 'Inter', sans-serif;
`;

const PlaceholderMessage = styled.div`
  font-size: 0.9rem;
  color: #999;
  padding: 0.5rem;
  text-align: center;
`;
