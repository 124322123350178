import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageLayout } from '@/components/page-layout';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { storeDomain } from '@/features/shop-settings';
import { ExcludedTagsCard } from '@/features/global-rules/excluded-tags-card';
import { FeatureBit } from '@/webapi/use-auth-api';
import { VariantMetafieldsCard } from '@/features/global-rules/variant-metafields-card';

export function GlobalRulesPage() {
  const ctx = useContext(AccountContext);
  const domain = storeDomain(ctx);

  const allowedVariantMetafields = useFeatureBit(
    FeatureBit.VARIANT_METAFIELDS_SYNC,
  );

  return (
    <PageLayout hideBackButton={false} heading={`${domain} Global Rules`}>
      <Container>
        <ExcludedTagsCard />
        {allowedVariantMetafields && <VariantMetafieldsCard />}
      </Container>
    </PageLayout>
  );
}

export const Container = styled.div`
  width: 68%;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
`;
